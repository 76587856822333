<template>
    <div class="box_main_info" :style="rowCol">
        <div class="top_title">
            <div class="title smart-farming-title baseColor font-14">养殖情况</div>
            <el-date-picker
                v-model="searchParamDateVal"
                @change="searchParamDateChange"
                type="daterange"
                align="right"
                size="small"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                class="date_picker"
                >
                </el-date-picker>
         </div>
         <template v-if="list && list.length">
          <div :class="['content_info',isOpen? 'open':'']">
            <div class="list">
                <div v-for="item in list" :key="item.id">
                    <div class="img_box borderBase">
                       
                        <el-image style="width: 100%; height: 100%" :src="hanldeImg(item.imgs)" :preview-src-list="[hanldeImg(item.imgs)]" fit="cover">
                            <div slot="error" class="image-slot boxBg">
                            <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <p class="baseColor font-12 mt-10 tc">{{ item.farmingTypeName }}</p>
                    <p class="font-12 tc">{{ hanldeTime(item.time) }}</p>
                </div>
            </div>
            <div class="pages_box">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="animelFromInfo.pageIndex"
                    :page-size="animelFromInfo.pageSize"
                    layout="total, prev, pager, next"
                    class="pageInfo"
                    background
                    :total="picTotal"
                >
                </el-pagination>
            </div>
          </div>  
          <div class="arrow_box" v-if="isShowMore"><span @click="toOpen" :class="['iconfont', 'icon-jiantouxia', 'baseColor',isOpen ? 'arrow_icon': '','cp']"></span></div> 
         </template>
         <hnEmptyData size="small" v-else></hnEmptyData>
    </div>
</template>

<script>
    import { imgAddHost } from '@/libs/util.js'
    export default {
        props:{
            breedId:String,
            isFreeData:{
                type: Boolean,
                default: false
            } 
        },
        data(){
           return {
              isOpen: false,
              animelFromInfo:{
                pageIndex: 1,
                pageSize: 15,
                breedId: this.breedId,
                timeStart: null,
                timeEnd: null
              },
              picTotal: 0,
              list:[],
              searchParamDateVal: null
           }
        },
        computed: {
            rowCol () {
                return {
                    '--col': this.isFreeData ? 5 : 7
                }
            },
            isShowMore(){
                let length = this.list ?.length
                return this.isFreeData ? length > 7 ? true : false : length > 5 ? true : false
            },
            
        },
        // created(){
            
        //     this.reqTraFarmingPageList()
        // },
        methods:{
            hanldeTime(time){
                if(!time) return
                return time.split(' ')[0] 
            },
            hanldeImg(imgs){
              if(!imgs) return ''
              if(imgs.indexOf(',') > -1){
                let img = imgs.split(',')[0]
                return imgAddHost(img)
              }else{
                return imgAddHost(imgs)
              }
            },
            toOpen(){
                this.isOpen = !this.isOpen 
            },
            handleCurrentChange(){
                this.reqTraFarmingPageList()
            },
            searchParamDateChange(){
                
                 if(this.searchParamDateVal && this.searchParamDateVal.length){
                     this.animelFromInfo.timeStart = this.searchParamDateVal[0]
                     this.animelFromInfo.timeEnd = this.searchParamDateVal[1]
                 }else{
                     this.animelFromInfo.timeStart = null
                     this.animelFromInfo.timeEnd = null
                 }
                 this.reqTraFarmingPageList()
            },
            async reqTraFarmingPageList(){
              
                this.animelFromInfo.pageSize = this.isFreeData ? 15 : 21
                const res = await this.$api.TraFarmingPageList(this.animelFromInfo)
                this.list = res ?.list
                this.picTotal = res ?.totalCount || 0
            }
        }
    }
</script>

<style lang="scss" scoped>
 .el-image ::v-deep .image-slot {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.box_main_info{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    background: url('../../../../assets/imgs/smartFarming/xmyz/frame1.png') no-repeat;
    background-size: 100% auto;
    background-position: left bottom;
    .top_title{
       height: 34px; 
       margin-bottom: 10px;
       display: flex;
       align-items: center;
       justify-content: space-between;
       .title{
           width: 157px;
           height: 34px;
           line-height: 34px;
           text-indent: 48px;
       }
       .date_picker{
          margin-right: 40px;
          width: 300px;
          background: transparent;
          border: 1px solid #c6c6c6;
       }
       .date_picker ::v-deep .el-range-input{
          background: transparent;
       }
    }
    .content_info{
      height: 120px;
      overflow: hidden;
    //   background-color: red;
      transition: height 0.5s;
      .list{
        padding: 0 40px;
        height: 390px;
        display: grid;
        grid-template-columns: repeat(var(--col), 1fr);
        grid-gap: 20px;
        grid-template-rows: repeat(3, 1fr);
        .img_box{
            width: 100%;
            height: 72px;
            & > img {
                width: 100%;
                height: 100%;
            }
        }
      }
    }
    .open{
        height: 440px;
    }
    .arrow_icon{
        transform: rotate(180deg) !important;
    }
    .arrow_box{
        text-align: center;
        padding-bottom: 5px;
        & > span {
            font-size: 22px;
            transform: rotate(0deg);
            transition: all 0.5s;
            display: block;
        }
    }
    .pages_box{
        text-align: center;
        margin-top: 15px;
    }
}
  
// .check-in {
//             display: block;
//       .raw {
//             height: 1em;
//             //图标默认向上
//             transform: rotate(180deg);
//             transition: all 0.5s;}
//       }
 
//       //收起
//       #check:checked ~ .box {
//           max-height: 0;
//           transform: translate(1.1);}
 
//       //旋转图标
//       #check:checked ~ .up-or-down .raw {
//           transform: rotate(360deg);}
 
//     .box {
//           //盒子默认展开
//           max-height: 70px;
//           overflow: hidden;
//           transition: max-height 0.5s;
//     }


</style>