<template>
    <div class="back_main_box">
         <span class="iconfont icon-fanhuifuzhi back_icon cp" @click="$emit('back')"></span>  
         <div class="code_box">
            <div class="code" v-for="(item,i) in codeText" :key="item+i">
                <div>{{ item }}</div>
            </div>
         </div> 
    </div>
</template>

<script>
    export default {
        props:{
            code: String
        },
        computed:{
            codeText(){
                if(!this.code) return []
                return this.code.split('')
            }
        },
        // methods:{
        //     toBack(){
        //         this.
        //     }
        // }
    }
</script>

<style lang="scss" scoped>
.back_main_box{
    display: flex;
    align-items: center;
    justify-content: center;
    .back_icon{
        font-size: 32px;
        color: var(--el-color-primary);
    }
    .code_box{
        display: flex;
        padding: 3px;
        border: 1px solid  var(--el-color-primary);
        margin-left: 20px;
        .code{
            width: 26px;
            height: 35px;
            position: relative;
            color: #fff;
           &::after{
             content: ' ';
             display: block;
             width: 26px;
             height: 17px;
             position: absolute;
             left: 0;
             top: 0;
             background: var(--el-color-primary);
             border-radius: 3px 3px 0 0;
           }
           &::before{
             content: ' ';
             display: block;
             width: 26px;
             height: 17px;
             position: absolute;
             left: 0;
             bottom: 0;
             background: var(--el-color-primary);
             border-radius:0 0 3px 3px;
            
           }
           div{
             width: 26px;
             height: 35px;
             line-height: 35px;
             text-align: center;
             position: absolute;
             font-size: 22px;
             left: 0;
             bottom: 0;
             z-index: 1;
           }

        }
        .code:not(:last-child){
            margin-right: 3px;
        }
    }
}

</style>