<template>
     <div class="detail_content_box wh100 smart-detail-bg-image">
        <div class="left_content">
            <baseInfo :animeId="animeId"></baseInfo>
            <grow :animalId="animeId"></grow>
            <epidemic :animalId="animeId"></epidemic>
        </div>
        <div class="center_content">
            <backInfo :code="earCode" @back="$emit('back')"></backInfo>
            <threeInfo ref="three" :type="modelType" v-if="modelType"></threeInfo>
            
            <cultivation :breedId="breedId" ref="cultivation" :isFreeData="isFreeData"></cultivation>
        </div>
        <div class="right_content" v-if="isFreeData">
            <drinkWater :reqData="waterData" v-if="hasWaterData"></drinkWater>
            <!-- <access></access> -->
            <inventory :list="inventoryData" :earCode="earCode" v-if="hasInventoryData"></inventory>
        </div>
     </div>
</template>

<script>
    import baseInfo from './base_info.vue'
    import access from './access.vue'
    import cultivation from './cultivation.vue'
    import drinkWater from './drink_water.vue'
    import epidemic from './epidemic.vue'
    import grow from './grow.vue'
    import inventory from './inventory.vue'
    import threeInfo from './three_info.vue'
    import backInfo from './back_info.vue'
    
    export default {
        components: { baseInfo,access,cultivation,drinkWater,epidemic,grow,inventory,threeInfo,backInfo },
        props:{
            animeInfo: Object
        },
        data(){
            return {
                waterData: null,
                inventoryData: [],
            }
        },
        created(){
            this.reqFreeData()
        },
        computed:{
           modelType(){
              if(!this.animeInfo ?.argProKindName) return ''
              const typePosition = [
                {
                    type: '猪',
                    val: 'zhu'
                },
                {
                    type: '牛',
                    val: 'niu'
                },
                {
                    type: '羊',
                    val: 'yang'
                }
              ]
              let typeInfo = ''
              typePosition.some(code => {
                
                 if(this.animeInfo.argProKindName.indexOf(code.type) > -1){
                     typeInfo = code.val
                     return true
                 } 
              })
              return typeInfo
             
           },
           animeId(){
              return this.animeInfo ?.id
           },
           earCode(){
              return this.animeInfo ?.earCode
           },
           breedId(){
              return this.animeInfo ?.breedId
           },
           hasWaterData(){
              return this.waterData ?.traDrinkDayViews && this.waterData.traDrinkDayViews.length ? true : false
           },
           hasInventoryData(){
               return this.inventoryData && this.inventoryData.length ?  true : false
           },
           isFreeData(){
              return this.hasWaterData || this.hasInventoryData
           }
        },
        methods:{
            async reqFreeData(){
                try {
                    const res = await Promise.all([this.$api.TraWebDrinkDayAnalysis({ animalId: this.animeId }),this.$api.ByEarCodeTraStockPlanAnimalPageList({ pageSize: 6,pageIndex: 1,earCode: this.earCode})])
                    this.waterData = res[0]
                    this.inventoryData = res[1] ?.list || []
                    this.$refs.cultivation.reqTraFarmingPageList()
                    this.$nextTick(() => {
                        this.$refs.three.updateSize()
                    })
                    // 
                } catch{

                }
            },
           
        }
    }
</script>

<style lang="scss" scoped>
.detail_content_box{
    box-sizing: border-box;
    padding: 40px 40px 30px 40px;
    display: flex;
    .left_content{
        width: 320px;
        height: 100%;
        display: flex;
        flex-direction: column;

    }
    .center_content{
        flex: 1;
        min-width: 0;
        position: relative;
    }
    .right_content{
        width: 320px;
        height: 100%;
    }
}
</style>