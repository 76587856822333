<template>
    <div class="dialog_content_info">
        <div class="row_box" v-for="item in list" :key="item.id">
            <div>{{ item.stockTime || '暂无' }}</div>
            <hn-format-dic v-model="item.stockStatus" :color="dicColor" type="stockStatus"></hn-format-dic>
        </div>
        <hnEmptyData size="small" v-show="!list || !list.length"></hnEmptyData>
        <div class="tc mt-16 mb-8">
            <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="model.pageIndex"
            :page-size="model.pageSize"
            layout="total, prev, pager, next"
            class="pageInfo"
            background
            :total="pageTotal">
            </el-pagination>
        </div>
    </div>  
</template>

<script>
    export default {
        props:{
            earCode: String
        },
        data(){
           return {
              list: [],
              model:{
                pageSize: 10,
                pageIndex: 1,
                earCode: this.earCode
              },
              pageTotal: 0
           }
        },
        created(){
 
            this.getData() 
        },
        computed:{
            dicColor(){
                return {1:'#fff',2:this.$themeColor,3:'#FFA800'}
            }
       },
        methods:{
           async getData(){
               const res = await this.$api.ByEarCodeTraStockPlanAnimalPageList(this.model)
               this.list = res ?.list
               this.pageTotal = res ?.totalCount || 0
           },
           handleCurrentChange(){
              this.getData() 
           }
        }
    }
</script>

<style lang="scss" scoped>
.dialog_content_info{
   max-height: 386px;
   padding-top: 10px;
   box-sizing: border-box;
   color: #fff;
      .row_box{
        //  width: 280px;
         height: 30px;
         margin: 0 auto;
         display: flex;
         & > div{
            text-align: center;
            height: 100%;
            line-height: 30px;
            font-size: 12px; 
         }
         & > div:first-child{ 
            flex: 3;
            
         }
         & > div:last-child{
            flex: 2;
         }
      }
      .row_box:nth-child(odd){
        border: 1px solid var(--el-color-primary);
        background: rgba(var(--sys-color-rgb-primary),.2); 
      }
}

</style>