<template>
    <div class="three_info_box">
      <div ref="threeBox" class="three_box"></div>
      <!-- <progressBar ref="bar" v-if="!isModel"></progressBar> -->
    </div>
</template>

<script>
    import createThreeModel from "@/libs/map.js";
    import progressBar from "./progressBar.vue";
    export default {
        components:{progressBar},
        props:{
            type: String
        },
        data(){
            return{
                mapObj:null
            }
        },
      
        mounted() {

            this.$nextTick(() => {
                this.threeInit();
                
            });
           
        },
        beforeDestroy(){
            this.mapObj.clearScene()
        },
        methods:{
            threeInit() {
                let mapObj = new createThreeModel(this.$refs.threeBox,{
                    type: this.type,
                    modelOk: this.updateOk.bind(this)
                });
                mapObj.init();
                this.mapObj = mapObj
            },
            updateSize(){
               this.mapObj.resizeEventHandle()
            },  
            updateOk() {
                this.$nextTick(() => {
                  this.$refs?.bar?.finish();
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
.three_info_box{
    width: 100%;
    height: 500px;
    position: absolute;
    left: 0;
    top: 60px;
    background: url('../../../../assets/imgs/smartFarming/xmyz/bottom.png') no-repeat;
    background-size: 100% 100%;
    & > .three_box{
        width: 100%;
        height: 100%;
    }
}
</style>