<template>
    <div class="board_main_box">
        <span class="iconfont icon-laba font-18 standbyColor mr-4"></span>
        <span class="standbyColor f16">预警信息：</span>
        <div @click="handleClick($event)">
         <vue-seamless-scroll :data="listData" class="seamless-warp" :key="scrollKey" :class-option="defaultOption">
            <ul>
                <li class="f14 warning_info_box" v-for="(item,i) in listData" :key="scrollKey+i">
                    <el-tooltip effect="dark" :content="item.alarmContent" placement="top">
                        <div class="sinleline cp">{{ item.alarmContent }}</div>       
                    </el-tooltip>
                    
                    <span class="time" v-if="item.viewTime">{{ item.viewTime }}</span>
                    <div :data-obj="item.id" class="read cp">已读</div>
                </li>
            </ul>
        
        </vue-seamless-scroll>
      </div>
        
    </div>
</template>

<script>

   
    export default {

        props:{
           listData:{
               type: Array,
               default: () => []
                    
           }
        },
        data(){
            return {
                defaultOption: {
                
                    singleHeight: 28,
                    waitTime: 4000,
                    // openWatch: true,
                    // hoverStop: true,
                    direction: 1,
                    limitMoveNum: 2
                
                },
                scrollKey: new Date().getTime(),
           
    
            }
        },
        created(){

        },
        methods:{
            updateData(){
               this.scrollKey = new Date().getTime()

            },
            async handleClick($event){
                console.log($event)
                let id = $event.target.dataset ?.obj
                if(!id) return
                const res = await this.$api.SysAdminMessageRead({id})    
                if(res) {
                   this.$msg.success('设置成功') 
                   this.$emit('update')
                }        
                // if(this.isNewsScoll) this.stopAnime()
                // const cbData = {
                //     id: $event.target.dataset.obj,
                //     el: $event
                // }
                // this.$emit('change',cbData)
                
              
                
            },
        }
    }
</script>

<style lang="scss" scoped>
.board_main_box{
    height: 100%;
        display: flex;
        align-items: center;
        // background: #263844;
        padding: 0 10px;
        border-radius: 5px;
        .warning_info_box{
            display: flex;
            align-items: center;
            height: 28px;
            //line-height: 19px;
            & > span:first-child{
                // display: inline-block;
                // max-width: 700px;
            }
           .time{
                flex-shrink: 0;
                color: #888;
                margin: 0 10px 0 20px;
            }
            .read{
                flex-shrink: 0; 
                color: #fff;
                border: 1px solid var(--el-color-primary);
                padding: 3px 10px;
                border-radius: 3px;
                font-size: 12px;
                background-color: rgba(var(--sys-color-rgb-primary),0.1);
            }
        }
        .seamless-warp {
            // width: 400px;
          height: 28px;
          overflow: hidden;
        //   position: relative;
        //   overflow-y: auto;
        }
        .mr10{
            margin-right: 10px;
        }
}
.warp {
    height: 19px;
    width: 360px;
    margin: 0 auto;
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      li,
      a {
        display: block;
        height: 19px;
        line-height: 19px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }
    }
  }
</style>