<template>
    <div class="box_main_info">
         <div class="top_title">
            <div class="title smart-farming-title baseColor font-14">基本信息</div>
         </div>
         <div class="content_info" v-if="detailsInfo">
            <div class="info_box smart-farming-base-info-bg">
                <div class="header_row row_box">
                    <div>入栏类型：<span class="baseColor">{{ $formatter(detailsInfo.inputType,'lairageType') }}</span></div>
                    <div>状态：<span :style="animelButState">{{ $formatter(detailsInfo.status,'animalType') }}</span></div>
                </div>
                <div class="col_box">
                    <div class="row_box">
                        <div>性别：<span :class="[detailsInfo.sex == 2 ? 'redC' : 'baseColor']">{{ $formatter(detailsInfo.sex,'animalSex') }}</span></div>
                        <div>身高：<span>{{ detailsInfo.height ? `${detailsInfo.height}cm`: '暂无'}}</span></div>
                        <div>体长：<span>{{ detailsInfo.length ? `${detailsInfo.length}cm`: '暂无'}}</span></div>
                        <div>体重：<span>{{ detailsInfo.weight ? `${detailsInfo.weight}kg`: '暂无'}}</span></div>
                        <div>年龄：<span :class="[{offLineColor: !oldYear}]">{{ oldYear ? oldYear + '岁' : '暂无'}}</span></div>
                        <div>体温：<span :class="[detailsInfo.temperature ? 'standbyColor' : 'offLineColor']">{{ detailsInfo.temperature ? detailsInfo.temperature + '℃' : '暂无'}}</span></div>
                        <div>类别：<span :class="[detailsInfo.agrProKindName ? 'baseColor' : 'offLineColor']">{{ detailsInfo.agrProKindName }}</span></div>
                        
                    </div>
                    <div class="row_box">
                        <div>出生日期：<span>{{ setDate }}</span></div>
                        <div><p>智能耳标：</p><span :class="[{offLineColor: !detailsInfo.iotDevCode}]">{{ detailsInfo.iotDevCode || '暂无' }}</span></div>
                        <div><p>智能项圈：</p><span :class="[{offLineColor: !detailsInfo.necklaceCode}]">{{ detailsInfo.necklaceCode || '暂无'}}</span></div>
                        <div><p>父系耳标：</p><span :class="[{offLineColor: !detailsInfo.paternal}]">{{ detailsInfo.paternal || '暂无'}}</span></div>
                        <div><p>母系耳标：</p><span :class="[{offLineColor: !detailsInfo.female}]">{{ detailsInfo.female || '暂无' }}</span></div>
                    </div>
              </div>
              <div class="mt-6">累计步数：<span :class="[detailsInfo.todayWalk ? 'baseColor' : 'offLineColor']">{{ detailsInfo.todayWalk ? detailsInfo.todayWalk +'步': '暂无' }}</span></div>
                
            </div>
         </div>  
    </div>
</template>

<script>
    export default {
        props:{
            animeId: String
        },
        data(){
            return {
                 detailsInfo: null
            }
        },
        created(){
             this.getData()  
        },
        computed:{
            animelButState(){
                const info = {
                    '1': '#32A1FF',
                    '2': '#FF5A00',
                    '3': '#FFA800',
                    '4': '#3D58FF'
                }
                let state = this.detailsInfo ?.status
           
                let color = info[state] || '#3D58FF'
                return { color }
            },
            setDate(){
               if(!this.detailsInfo ?.birthday) return '暂无'
               return this.detailsInfo.birthday.split(' ')[0]
            },
            oldYear(){
                 if(!this.setDate) return ''
                 let sDate = new Date(this.setDate).getFullYear()
                 let eDate = new Date().getFullYear()
               
                 let result = eDate - sDate
                 return result > 0 ? result : 1 
            }
        },
        methods:{
            async getData(){
                const res = await this.$api.TraAnimalInfoDetail({animalId: this.animeId})
                
                this.detailsInfo = res
            }
        }
    }
</script>

<style lang="scss" scoped>
.box_main_info{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .redC{
       color: #FF5B5B
    }
    .top_title{
       height: 34px; 
       .title{
           width: 157px;
           height: 34px;
           line-height: 34px;
           text-indent: 48px;
       }
    }
    .content_info{
      flex: 1;
      min-height: 0;
      padding: 10px 0;
      .info_box{
         width: 282px;
         height: 222px;
         margin: 0 auto;
         font-size: 12px;
         padding: 0 20px;
         box-sizing: border-box;
         .header_row{
              border-bottom: 1px solid var(--el-color-primary);
              padding: 8px 0 !important;
              display: flex;
                // margin-top: 6px;
                & > div:first-child{
                    flex: 2;
                }
                & > div:last-child{
                    flex: 3;
                }
         }
         .col_box{
            display: flex;
              
            & > div:first-child{
                flex: 2;
            }
            & > div:last-child{
                flex: 3;
            }
            .row_box{
              & > div{
                  margin-top: 6px;
                  display: flex;
                  & > span {
                    word-wrap:break-word;
                    word-break:break-all
                  }
                  & > p {
                     flex-shrink: 0;
                  }
              }
            }
         }
         
           
      }
    }
    .mt-6{
        margin-top: 6px;
    }
}
</style>