<template>
    <div class="box_main_info">
         <div class="top_title">
            <div class="title smart-farming-title baseColor font-14">生长曲线</div>
         </div>
         <div class="content_info">
            <div id="growChart" v-if="hasData"></div>
            <hnEmptyData size="small" v-else></hnEmptyData>
         </div>  
    </div>
</template>

<script>
    import { optionGrowBarLine } from '@/libs/echartsOption'
    export default {
        props:{ animalId: String },
        data(){
            return {
                hasData: true
            }
        },
        mounted(){
            this.getData()  
            
        },
        methods:{
            echartInfo(options){
                let myChart = this.$echarts.init(document.getElementById('growChart'))
                
                myChart.setOption(optionGrowBarLine(options))
                window.addEventListener("resize", function () {
                  myChart.resize();          
                })
            }, 
            setDate(time){
              return time ?.split(' ')[0]
            },
            async getData(){
                const res = await this.$api.TraAnimalInfoGrowDetail({ animalId: this.animalId})
                console.log(res)
                if(res ?.traAnimalInfoGrows && res.traAnimalInfoGrows.length){
                    let xAxis = []
                    let height= [],weight= [],length= []
                    res.traAnimalInfoGrows.map(item => {
                        let time = this.setDate(item.weighTime)
                        xAxis.push(time)
                        height.push(item.bodyHeight)
                        weight.push(item.weight)
                        length.push(item.bodyLength)
                    })
                    const options = {
                            xAxis,
                            charts: [
                                {
                                    name: '身高',
                                    unit: 'cm',
                                    yAxis: height
                                },
                                {
                                    name: '体长',
                                    unit: 'cm',
                                    yAxis: length
                                },
                                {
                                    name: '体重',
                                    unit: 'kg',
                                    yAxis: weight
                                },
                            ]
                    }
                    this.echartInfo(options)
                }else this.hasData = false
            }
        }
    }
</script>

<style lang="scss" scoped>
.box_main_info{
    width: 100%;
   height: 210px;
    // display: flex;
    // flex-direction: column;
    position: relative;
    flex-shrink: 0;
    .top_title{
       height: 34px; 
       position: absolute;
       left: 0;
       top: 0;
       .title{
           width: 157px;
           height: 34px;
           line-height: 34px;
           text-indent: 48px;
       }
    }
    .content_info{
       width: 100%;
       height: 100%;
    //   background-color: red;
    }
    #growChart{
        width: 100%;
        height: 100%;
    }
}
</style>