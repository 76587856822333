<template>
   <div class="box_main_info">
        <div class="top_title">
           <div class="title smart-farming-title baseColor font-14">盘点记录</div>
           <p class="more_box cp baseColor" @click="toMore">更多</p>
        </div>
        <div class="content_info">
            <div class="row_box" v-for="item in list" :key="item.id">
               <div>{{ item.stockTime || '暂无' }}</div>
               <div><hn-format-dic v-model="item.stockStatus" :color="dicColor" type="stockStatus"></hn-format-dic></div>
            </div>
            <hnEmptyData size="small" v-show="!list || !list.length" class="mt-20"></hnEmptyData>
        </div>  
   </div>
</template>

<script>
   import scrollInfo from './dialog/inventoryInfo.vue'
   export default {
       props:{ 
         list: {
            type: Array,
            default: () => []
         },
         earCode: String 
       },
       computed:{
            dicColor(){
                return {1:'#fff',2:this.$themeColor,3:'#FFA800'}
            }
       },
       methods:{
          
          toMore(){
            this.showDialog(scrollInfo,{props:{earCode: this.earCode}},{
                 width:'420px',
                 title: '盘点记录',
                 dialog:{
                   isCenter: true,
                   isBut: false
                 }
               },(pwd)=> {
              
                   // 批量加入队列
                 
             })
          }
       }
   }
</script>

<style lang="scss" scoped>
.box_main_info{
   width: 100%;
   flex: 1;
   display: flex;
   flex-direction: column;
   flex-shrink: 0;
   .top_title{
      height: 34px; 
      display: flex;
      justify-content: space-between;
      align-items: center;
      .more_box{
         font-size: 12px;
         border-bottom: 1px solid var(--el-color-primary);  
      }
      .title{
          width: 157px;
          height: 34px;
          line-height: 34px;
          text-indent: 48px;
      }
   }
   .content_info{
     flex: 1;
     min-height: 0;
     padding-top: 10px;
     box-sizing: border-box;
     .row_box{
        width: 280px;
        height: 30px;
        margin: 0 auto;
        display: flex;
        & > div{
            text-align: center;
            height: 100%;
            line-height: 30px;
            font-size: 12px; 
         }
         & > div:first-child{ 
            flex: 3;
            
         }
         & > div:last-child{
            flex: 2;
         }
     }
     .row_box:nth-child(odd){
       border: 1px solid var(--el-color-primary);
       background: rgba(var(--sys-color-rgb-primary),.2);
     }


   }
}
</style>