<template>
    <div class="dialog_content_info">
        <div class="row_box" v-for="item in list" :key="item.id">
        <div>{{ item.diseaseName }}</div>
        <div class="baseColor">已注射</div>
        </div>
        <hnEmptyData size="small" v-show="!list || !list.length"></hnEmptyData>
        <div class="tc mt-16 mb-8">
            <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="model.pageIndex"
            :page-size="model.pageSize"
            layout="total, prev, pager, next"
            class="pageInfo"
            background
            :total="pageTotal">
            </el-pagination>
        </div>
    </div>  
</template>

<script>
    export default {
        props:{
          animalId: String
        },
        data(){
           return {
              list: [],
              model:{
                pageSize: 10,
                pageIndex: 1,
                animalId: this.animalId
              },
              pageTotal: 0
           }
        },
        created(){
         console.log(this.animalId)
            this.getData() 
        },
        methods:{
           async getData(){
               const res = await this.$api.TraAnimalDiseasePageList(this.model)
               this.list = res ?.list
               this.pageTotal = res ?.totalCount || 0
           },
           handleCurrentChange(){
              this.getData() 
           }
        }
    }
</script>

<style lang="scss" scoped>
.dialog_content_info{
   max-height: 386px;
   padding-top: 10px;
   box-sizing: border-box;
   color: #fff;
      .row_box{
        //  width: 280px;
         height: 30px;
         margin: 0 auto;
         display: flex;
         & > div{ 
            flex: 1;
            text-align: center;
            height: 100%;
            line-height: 30px;
            font-size: 12px;
         }
      }
      .row_box:nth-child(odd){
        border: 1px solid var(--el-color-primary);
        background: rgba(var(--sys-color-rgb-primary),.2);
      }
}

</style>