<template>
    <div class="box_main_info">
         <div class="top_title">
            <div class="title smart-farming-title baseColor font-14">门禁监管</div>
            <p class="more_box cp baseColor">更多</p>
         </div>
         <div class="content_info">
             <div class="row_box">
                <div>口蹄疫</div>
                <div class="baseColor">已注射</div>
             </div>
             <div class="row_box">
                <div>口蹄疫</div>
                <div class="baseColor">已注射</div>
             </div>
             <div class="row_box">
                <div>口蹄疫</div>
                <div class="baseColor">已注射</div>
             </div>
             <div class="row_box">
                <div>口蹄疫</div>
                <div class="baseColor">已注射</div>
             </div>
             <div class="row_box">
                <div>口蹄疫</div>
                <div class="baseColor">已注射</div>
             </div>
             <div class="row_box">
                <div>口蹄疫</div>
                <div class="standbyColor">未注射</div>
             </div>
         </div>  
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.box_main_info{
    width: 100%;
    height: 240px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .top_title{
       height: 34px; 
       display: flex;
       justify-content: space-between;
       align-items: center;
       .more_box{
          font-size: 12px;
          border-bottom: 1px solid var(--el-color-primary);  
       }
       .title{
           width: 157px;
           height: 34px;
           line-height: 34px;
           text-indent: 48px;
       }
    }
    .content_info{
      flex: 1;
      min-height: 0;
      padding-top: 10px;
      box-sizing: border-box;
      .row_box{
         width: 280px;
         height: 30px;
         margin: 0 auto;
         display: flex;
         & > div{ 
            flex: 1;
            text-align: center;
            height: 100%;
            line-height: 30px;
            font-size: 12px;
         }
      }
      .row_box:nth-child(odd){
        border: 1px solid var(--el-color-primary);
        background: rgba(var(--sys-color-rgb-primary),.2);
      }
    //   background-color: red;

    }
}
</style>