<template>
    <div class="wh100 smart_farming_main_box">
        <HnContent :hasData='true'>
            <template slot="left"> 
                <areaMenu @change="menuChange"></areaMenu>
                <!-- <HnMenu :menuDevType="['采集设备','采控设备']" :actuatorFun="1" ></HnMenu> -->
            </template>
            <template slot="right">
                <div 
                    v-loading="changLoading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(40, 53, 63, 0.8)"
                    class="wh100 content_box">
                    <template v-if="!isDeatail">
                        <div class="warning_box boxBg" v-if="warnList && warnList.length"><warnBoard :listData ='warnList' @update="SysAdminMessageHadoopList"></warnBoard></div>
                        <div class="content_data_box boxBg">
                            <div class="nowData_title_box">
                                <div>
                                    <span class="f16">环境监测</span>
                                    <el-select class="ml-20 custom_input" v-model="monitorId" @change="devChange" size="small" style="width: 200px" placeholder="请选择采集器">
                                        <el-option v-for="item in monitorOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="nowData_content_box monitoring-nowdata-bg-image"  v-loading="nowDataLoading"  element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(21, 35, 45, 0.8)" ref="warp">
                              <topScroll :list="nowDataList "></topScroll>
                            </div>
                            <div class="animel_tool_box">
                                <div>
                                    <el-input class="custom_input" v-model="animelFromInfo.earCode" size="small" style="width: 200px" placeholder="耳标查询"></el-input>
                                    <el-select class="ml-20 custom_select custom_input" v-model="animelFromInfo.status" size="small" style="width: 100px" placeholder="状态查询">
                                        <el-option v-for="item in $dictionary('animalType')" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                    </el-select>
                                    <el-button  plain size="small" class="searchBut cp" @click="toSearch">搜索</el-button>
                                    <el-button  plain size="small"  class="resetBut cp" @click="toReset">重置</el-button>
                                    <el-button type="warning" plain size="small"  class="downBut cp" @click="animelDownload" v-if="hasAnimelData">导出</el-button>
                                </div>
                                <div>
                                    <div><span class="iconfont icon-nvxing redC font-20"></span>雌性</div>
                                    <div class="ml-10"><span class="iconfont icon-nanxing baseColor font-20"></span>雄性</div>
                                </div>
                            </div>
                            <div class="animel_list_box pageBg" v-loading="animelDataLoading"  element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(21, 35, 45, 0.8)">
                              <template v-if="hasAnimelData">
                                <div class="list">
                                    <div class="row_box" @click="toDetail(item)" v-for="item in animelList" :key="item.id">
                                        <div class="row_top" :style="animelButState(item.status)">
                                            <div>
                                                <div class="d-flex align-items-center">
                                                    <div class="but" :style="animelButState(item.status)">{{ $formatter(item.status,'animalType')}}</div>
                                                    <span :class="['iconfont', 'font-20',item.sex == 2 ? 'icon-nvxing redC' : 'icon-nanxing baseColor']"></span>
                                                </div>
                                                <div class="font-14" :style="animelTextState(item.status)">{{ item.earCode }}</div>
                                            </div>
                                        </div>
                                        <div class="row_content boxBg">
                                            <div>
                                                <div class="icon" :style="animelPositionState(item)"></div>
                                                
                                            </div>
                                            <div>
                                                <p>身高：<span>{{ item.height ? `${item.height}cm`: '无数据'}}</span></p>
                                                <p>体长：<span>{{ item.length ? `${item.length}cm`: '无数据'}}</span></p>
                                                <p>体重：<span>{{ item.weight ? `${item.weight}kg`: '无数据'}}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pages_box">
                                    <el-pagination
                                        @current-change="handleCurrentChange"
                                        :current-page.sync="animelFromInfo.pageIndex"
                                        :page-size="15"
                                        layout="total, prev, pager, next"
                                        class="pageInfo"
                                        background
                                        :total="animelTotal"
                                    >
                                    </el-pagination>
                                </div>
                               </template>
                                <hnEmptyData size="small" v-else-if="!animelDataLoading"></hnEmptyData>
                            </div>
                        </div>
                    </template>
                    <detailInfo @back="toBack" :animeInfo="animeInfo" v-else></detailInfo>
                    
                </div>
            </template>
        </HnContent>
    </div>
</template>

<script>
    import detailInfo from './components/detail_info.vue'
    import areaMenu from './components/areaMenu'
    import warnBoard from './components/warn_board.vue'
    import topScroll from './components/topScroll.vue'
    import { imgAddHost } from '@/libs/util.js'
   
    export default {
        components: { detailInfo,areaMenu,warnBoard,topScroll },
        data(){
            return {
                changLoading: false,
                // leftStyle: null,
                timer: (new Date()).getTime(),
                nowDataList:[],
                animelFromInfo: {
                    pageSize: 15,
                    pageIndex: 1,
                    status: '',
                    earCode: ''
                },
                animelTotal: 0,
                monitorId: '',
                monitorOption: [],
                animelList: [],
                areaId: '',
                isDeatail: false,
                warnList: [],
                animeInfo: null,
                nowDataLoading: false,
                animelDataLoading: false,
                houseId: '',
                
            }
        },
        computed:{
            hasAnimelData(){
               return  this.animelList && this.animelList.length 
            }
          
        },
        created(){
             this.SysAdminMessageHadoopList()
        },
        methods:{
            devChange(){
                this.reqIotDevSensorChooseList()
            },
            // 获取区域设备列表
            async reqAreaDeviceList(){
                const res = await this.$api.AreaDeviceList({id: this.areaId,devTypeIds:["1001", "1003"]})
            
                if(res && res.length){
                    this.monitorOption = res
                    this.monitorId = res[0].id
                    this.reqIotDevSensorChooseList()
                }else{
                    this.monitorOption = []
                    this.monitorId = ''
                    this.nowDataList = []
                }
                
            },  
            // 获取采集数据
            async reqIotDevSensorChooseList(){
                    this.nowDataLoading = true
                    const res = await this.$api.IotDeviceRealTimeDataByCacheList({devId: this.monitorId})
                    setTimeout(() => {this.nowDataLoading = false},300)
                    if(res ?.devRealTimeDatas && res.devRealTimeDatas.length){
                        this.nowDataList = res.devRealTimeDatas
                    }else{
                        this.nowDataList = []
                    }
            },    
            menuChange(val){
                let arrVal = val[0].split('-')
                this.areaId = arrVal[1]
                this.houseId = arrVal[0]
                this.reqAreaDeviceList()
                this.TraAnimalInfoPageList()
                this.toBack()
            },
            // 获取告警内容
            async SysAdminMessageHadoopList(){
                const res = await this.$api.SysAdminMessageHadoopList({alarmType: 5,isView:2})
                this.warnList = res
            }, 
            // 获取动物信息
            async TraAnimalInfoPageList(){
                this.animelList = []
                if(!this.houseId) return
                this.animelDataLoading = true
                let reqData = Object.assign({},this.animelFromInfo,{houseId: this.houseId})
                const res = await this.$api.TraAnimalInfoPageList(reqData)
                this.animelTotal = res ?.totalCount 
                let list = res ?.list || []
                if(list && list.length){
                    this.$store.commit('setThreeModel',{name:list[0].argProKindName,onOk:()=> {
                      
                        this.animelDataLoading = false
                        this.animelList = list
                        
                    }})
                }else{
                    this.animelDataLoading = false
                 
                    this.$store.commit('clearModel')
                } 
            },
            toBack(){
                this.isDeatail = false
            },
            animelButState(state){
                const info = {
                    '1': '#32A1FF',
                    '2': '#FF5A00',
                    '3': '#FFA800',
                    '4': '#3D58FF'
                }
                let color = info[state] || '#3D58FF'
                return { background: color}
            },
            animelTextState(state){
                
                const info = {
                    '1': '#32A1FF',
                    '2': '#FF5A00',
                    '3': '#FFA800',
                    '4': '#3D58FF'
                }
                let color = info[state] || '#3D58FF'
                return { color}
            },
            animelPositionState(item){
              const statePosition = {
                 '1': 10,
                 '2': 88,
                 '3': 166,
                 '4': 244
              }
              const typePosition = [
                {
                    type: '猪',
                    val: 10
                },
                {
                    type: '牛',
                    val: 88
                },
                {
                    type: '羊',
                    val: 166
                }
              ]
              
              let stateInfo = statePosition[item.status] || 244
              let typeInfo = 244
              typePosition.some(code => {
                
                 if(item.argProKindName.indexOf(code.type) > -1){
                
                     typeInfo = code.val
                     return true
                 } 
              })
              
              return { backgroundPosition: `-${stateInfo}px -${typeInfo}px`}
            },
            handleCurrentChange(){
                this.TraAnimalInfoPageList()
            },
            async animelDownload() {
                const fileName = await this.$api.TraNewAnimalInfoExport({houseId:this.houseId,earCode: this.animelFromInfo.earCode,status:this.animelFromInfo.status});
                let url = imgAddHost(fileName);
                if (url) location.href = url;
            },
            toSearch(){
                this.TraAnimalInfoPageList()
            },
            toReset(){
                this.animelFromInfo= {
                    pageSize: 15,
                    pageIndex: 1,
                    status: '',
                    earCode: ''
                },
                this.TraAnimalInfoPageList()
            },
            toDetail(item){
                console.log('详情')
               this.isDeatail = true
               this.animeInfo = item
            }
        }
    }
</script>

<style lang="scss" scoped>
.smart_farming_main_box{
  
    .content_box{
       border-radius: 8px;
       box-sizing: border-box;
       padding:0 20px;
       display: flex;
       flex-direction: column;
       
       .warning_box{
          width: 100%;
          height: 40px;
          margin-bottom: 10px;
          border-radius: 10px;
       }
       .content_data_box{
            flex: 1;
            min-height: 0;
            border-radius: 10px;
            padding:0 10px 10px 10px;
            display: flex;
            flex-direction: column;
            .nowData_title_box{
                color: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 10px 14px 10px;
                box-sizing: border-box;
            }
            .nowData_content_box{
                width: 100%;
                height: 120px;
                border-radius: 8px;
                // overflow: hidden;
                // position: relative;
             
            }
            .animel_tool_box{
                display: flex;
                padding: 16px 10px 14px 10px;
                box-sizing: border-box;
                & > div:first-child{
                    flex: 1;
                }
                & > div:last-child{
                    display: flex;
                    align-items: center;
                    & > div{
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                
            }
            .animel_list_box{
               flex: 1;
               min-height: 0;
               display: flex;
               flex-direction: column;
               padding: 20px;
               box-sizing: border-box;
               border-radius: 10px;
               .list{
                  flex: 1;
                  min-height: 0;
                  display: grid;
                  grid-template-columns: repeat(5, 1fr);
                  grid-gap: 20px;
                  grid-template-rows: repeat(3, 1fr);
                  .row_box{
                      .row_top{
                          height: 40px;
                          border-radius: 20px 20px 0 0;
                          position: relative;
                          & > div{
                            width: 100%;
                            height: 35px;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background: var(--sys-color-box);
                            border-radius: 20px 20px 0 0;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 10px;
                            box-sizing: border-box;
                            .but{
                               padding: 0 15px;
                               height: 22px;
                               line-height: 22px;
                               text-align: center;
                               color: #fff;
                               border-radius: 11px;
                               margin-right: 3px;   
                            //    background-color: #32A1FF; 
                               font-size: 14px;
                            }
                          }
                      }
                      .row_content{
                         height: calc(100% - 40px);
                         border-top: 2px solid var(--sys-color-page);
                         border-radius:0 0 20px 20px;
                         display: flex;

                         & > div {
                            height: 100%;
                            font-size: 14px;
                           
                         }
                         & > div:last-child{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            flex: 4;
                         }
                         & > div:first-child{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex: 5;
                            .icon{
                               height: 68px;
                               width: 68px;
                               border-radius: 30px; 
                               background: url('../../../assets/imgs/smartFarming/xmyz/jlt.png') no-repeat;
                               background-position: -88px -10px;
                            }
                         }
                      }
                  }
               }
               .pages_box{
                 text-align: center;
               }
            }
       }
       
    }
    .redC{
       color: #FF5B5B
    }
    .searchBut{
        color: #fff;
        border: 1px solid #344552;
        background: rgba(#344552,0.2);
        margin-left: 15px;
    }
    .resetBut{
        color: #fff;
        border: 1px solid var(--el-color-primary);
        // background: var(--el-color-primary-light-2);
        margin-left: 15px;
    }
    .downBut{
    //    margin-left: 12px;
        min-width: 80px;
        color: #fff;
        border: 1px solid #ffa800;
        background: rgba(#ffa800,0.2);
        margin-left: 15px;
    }
    .custom_input ::v-deep .el-input__inner{
        background: transparent;
        border: 1px solid var(--sys-color-subbg)
    }
 
}
</style>