import * as THREE from 'three'
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
// import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js"
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import store from '@/store'
export default class createThreeModel {
    constructor(container,options){
        this.container = container ? container : document.body;
        this.width = this.container.offsetWidth
        this.height = this.container.offsetHeight
        const {
            type,
            modelOk = () => {},
            
        } = options
        this.modelType = type
        this.modelOk = modelOk
    }
    init(){
        if (!this.renderer) {
            this.renderer = new THREE.WebGLRenderer( { antialias: true,alpha: true,logarithmicDepthBuffer: true} );
        }
        this.renderer.shadowMap.enabled = true; // 开启阴影
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		this.renderer.toneMappingExposure = 1.25;
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize(this.width, this.height);
        this.container.appendChild(this.renderer.domElement); 
        
         // 场景
        this.scene = new THREE.Scene();
         // 相机 透视相机
         this.camera = new THREE.PerspectiveCamera(45, this.width / this.height, 0.1, 5000);
         // this.camera.position.set(0, -540, 576);
         
         let z = this.modelType == 'niu' ? 580 : 4
         this.camera.position.set(0, 0, z);
         this.camera.lookAt(0, 0, 0);
        //  this.onSetSceneImage()
         this.setController(); // 设置控制

         this.setLight(); // 设置灯光

         this.animate();  // 加载动画
         this.modelType == 'niu' ? this.loadFbxModel() : this.loadModel()
         this.setResize(); // 绑定浏览器缩放事件
//          const axesHelper = new THREE.AxesHelper(10)
// this.scene.add(axesHelper)
    }
    setController() {
        this.controller = new OrbitControls(this.camera, this.renderer.domElement);
     
        this.controller.autoRotate = true 
        this.controller.enablePan = false; // 禁止右键拖拽
        this.controller.enableRotate =false;
      /* this.controller.enableZoom = true; // false-禁止右键缩放
        
        this.controller.maxDistance = 200; // 最大缩放 适用于 PerspectiveCamera
        this.controller.minDistance = 50; // 最大缩放*/

        // this.controller.enableRotate = false; // false-禁止旋转 
        
        // 上下旋转范围
        this.controller.minPolarAngle =0.2*Math.PI;
        this.controller.maxPolarAngle = 0.4 *Math.PI;
        // 左右旋转范围
        // this.controller.minAzimuthAngle = 0;
        // this.controller.maxAzimuthAngle = 0;

         this.controller.minZoom = 1; // 最小缩放 适用于OrthographicCamera
        this.controller.maxZoom = 1; // 最大缩放 
        this.controller.update();

    }
    onSetSceneImage() {

        const url= require('../assets/imgs/smartFarming/xmyz/bottom.png')
		this.scene.background = new THREE.TextureLoader().load(url);

      
	}
    setLight() {
        let ambientLight = new THREE.AmbientLight(0xffffff, 1); // 环境光
        this.scene.add(ambientLight);
            
    }
    animate() {
        this.animationId = requestAnimationFrame(this.animate.bind(this));
        if (this.raycaster) {
            this.raycaster.setFromCamera(this.mouse, this.camera);            
        }
        
        this.camera.updateMatrixWorld();
        // this.csm.update();
        this.controller.update();
            // csmHelper.update();
        if (!this.renderer) {
            this.renderer = new THREE.WebGLRenderer( { antialias: true,alpha: true,logarithmicDepthBuffer: true} );
        }
        this.renderer.render(this.scene, this.camera);

       
    }
    setResize() {
        window.addEventListener('resize', this.resizeEventHandle.bind(this))
    }
    resizeEventHandle() {
        this.width = this.container.offsetWidth
        this.height = this.container.offsetHeight
        console.log(this.width)
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
     }
    loadModel(){
        // this.glbLoader = new GLTFLoader()
        // this.dracoLoader = new DRACOLoader()
        // this.dracoLoader.setDecoderPath('./three/examples/js/libs/draco/gltf/')
        // // this.dracoLoader.setDecoderConfig({type: 'js'})
        // this.dracoLoader.preload()
        // this.glbLoader.setPath('./three/examples/js/libs/draco/gltf/')
        // this.glbLoader.setDRACOLoader(this.dracoLoader)
        // '/threemodel/'+ this.modelType +'.glb'
        // this.glbLoader.load('./threemodel/'+ this.modelType +'.glb',(gltf) => {
        //     this.modelOk()
        //     this.scene.add(gltf.scene)
        //     gltf.scene.position.set(0,-0.3,0)
        //     gltf.scene.scale.set(1.2, 1.2, 1.2)
        // })
        let gltf = store.state.threeModel
        this.scene.add(gltf.scene)
        gltf.scene.position.set(0,-0.3,0)
        gltf.scene.scale.set(1.2, 1.2, 1.2)
    }
    loadFbxModel(){
     

        // this.FBXLoader = new FBXLoader()
        
        // this.FBXLoader.load('./threemodel/niu.fbx',(fbx) => {
       
        //     // this.scene.add(gltf.scene)
        //     // gltf.scene.position.set(0,-0.3,0)
        //     // gltf.scene.scale.set(1.2, 1.2, 1.2)
        //     this.modelOk()
        //     this.scene.add(fbx)
        //     fbx.position.set(0,-30,0)
        //     fbx.scale.set(1.1, 1.1, 1.1)
            
        // })
        let fbx = store.state.threeModel
        this.scene.add(fbx)
        fbx.position.set(0,-30,0)
        fbx.scale.set(1.1, 1.1, 1.1)
    }
    clearScene() {
        cancelAnimationFrame(this.animationId);
        this.scene.traverse((child) => {
          if (child.material) {
            child.material.dispose();
          }
          if (child.geometry) {
            child.geometry.dispose();
          }
          child = null;
        });
        this.container.innerHTML = '';
        this.renderer.forceContextLoss();
        this.renderer.dispose();
        this.scene.clear();
        
        this.scene = null;
        this.camera = null;
        this.controller = null;
        this.renderer.domElement = null;
        this.renderer = null;
        this.container = null;
        console.log('clearScene');
    }
    

}