import { render, staticRenderFns } from "./access.vue?vue&type=template&id=688ae91d&scoped=true&"
import script from "./access.vue?vue&type=script&lang=js&"
export * from "./access.vue?vue&type=script&lang=js&"
import style0 from "./access.vue?vue&type=style&index=0&id=688ae91d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "688ae91d",
  null
  
)

export default component.exports