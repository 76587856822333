<template>
    <div class="box_main_info">
         <div class="top_title">
            <div class="title smart-farming-title baseColor font-14">饮水监测</div>
            <div class="font-12">今日饮水：<span class="baseColor">{{ dayWaterNum }}</span>次</div>
         </div>
         <div class="content_info">
            <div id="waterChart" v-if="hasData"></div>
            <hnEmptyData size="small" v-else></hnEmptyData>
         </div>  
    </div>
</template>

<script>
    import { optionWaterLine } from '@/libs/echartsOption'
    export default {
        props:{ reqData: Object },
        data(){
            return {
                hasData: true,
                dayWaterNum: 0
            }
        },
        mounted(){
          this.getData()
          
        },
        methods:{
            echartInfo(options){
                let myChart = this.$echarts.init(document.getElementById('waterChart'))
              
                myChart.setOption(optionWaterLine(options))
                window.addEventListener("resize", function () {
                    myChart.resize();          
                })
            },
            async getData(){
                // const res = await this.$api.TraWebDrinkDayAnalysis({ animalId: this.animalId })
                const res = this.reqData
                if(res){
                   let options = {
                    xAxis:[],
                    yAxis: [],
                    region: [res.minValue,res.maxValue]
                   }
                   res ?.traDrinkDayViews.map(item => {
                       options.xAxis.push(item.date)
                       options.yAxis.push(item.num)
                   })
                   this.dayWaterNum = res.num
                   this.echartInfo(options)
                }else{
                    this.hasData = false
                }
            } 
        }
    }
</script>

<style lang="scss" scoped>
.box_main_info{
    width: 100%;
    height: 240px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .top_title{
       height: 34px; 
       display: flex;
       justify-content: space-between;
       .title{
           width: 157px;
           height: 34px;
           line-height: 34px;
           text-indent: 48px;
       }
    }
    .content_info{
      flex: 1;
      min-height: 0;
      padding: 10px 0;
      
    }
    #waterChart{
        width: 100%;
        height: 100%;
    }
    
}
</style>